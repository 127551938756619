import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Grid,
  useTheme,
  useMediaQuery,
  Snackbar,
  Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Styled components remain the same...
const HeaderBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#f4811e',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: '4px 4px 0 0',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 1200,
  margin: '0 auto',
  boxShadow: theme.shadows[3],
}));

const ImageBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  border: '1px solid rgba(224, 224, 224, 1)',
  height: '45%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginBottom: '16px'
}));

const MissedNumbersDiv = styled('div')(({ theme }) => ({
  backgroundColor: '#b3e5fc',
  padding: theme.spacing(1),
  marginTop: theme.spacing(2),
  border: '1px solid #81d4fa',
  borderRadius: '4px',
}));

const RefreshNoteDiv = styled('div')(({ theme }) => ({
  backgroundColor: '#c8e6c9',
  padding: theme.spacing(1),
  marginTop: theme.spacing(2),
  border: '1px solid #a5d6a7',
  borderRadius: '4px',
}));

const ClinicDashboard = () => {
  const { clinicId } = useParams();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [title, setTitle] = useState('Welcome To ');
  const [tokens, setTokens] = useState([]);
  const [resp, setResponse] = useState({});
  const [missedNumbers, setMissedNumbers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchTokens = async () => {
    try {
      const response = await fetch(
        `https://notif-service-662226239165.asia-southeast1.run.app/display/token-status?clinicId=${clinicId}`,
        {
          method: 'POST',
          headers: {
            'accept': '*/*'
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch token data');
      }

      const data = await response.json();
      setTokens(data?.tokenList || []);
      setMissedNumbers(data?.missedToken || []);
      setTitle(data?.title);
      setResponse(data)
      setError(null);
    } catch (err) {
      setError('Failed to fetch latest token data');
      console.error('Error fetching tokens:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (clinicId) {
      fetchTokens();
      const intervalId = setInterval(fetchTokens, 60000);
      return () => clearInterval(intervalId);
    }
  }, [clinicId]);

  // Rest of the component remains the same...
  if (loading && tokens.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <StyledCard>
      <HeaderBox>
        <Typography variant="h5" component="h1" align="center" style={{ color: 'white' }}>
          {title}
        </Typography>
      </HeaderBox>
      <CardContent sx={{ height: "calc(100vh - 140px)" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={isSmallScreen ? 12 : 9}>
            <TableContainer component={Paper} elevation={0}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Token No.</TableCell>
                    {!isSmallScreen && <TableCell>Location</TableCell>}
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tokens.map((token) => (
                    <TableRow key={token?.number}>
                      <TableCell>{token?.number}</TableCell>
                      {!isSmallScreen && <TableCell>{token?.location}</TableCell>}
                      <TableCell>{token?.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <MissedNumbersDiv>
              Missed Q. Numbers: {missedNumbers.join(', ')}
            </MissedNumbersDiv>
            <RefreshNoteDiv>
              Note: This dashboard refreshes every 30 seconds.
            </RefreshNoteDiv>
          </Grid>
          {!isSmallScreen && (
            <Grid item sm={3}>
              <ImageBox>
                {resp?.image ? <img src={resp?.image} /> :
                  <Typography variant="subtitle1" align="center">
                    Image
                  </Typography>
                }
              </ImageBox>
              <ImageBox>
                {resp?.note ? <Typography variant="body2" align="center">
                  {resp?.note}
                </Typography> :
                  <Typography variant="body2" align="center">
                    Description
                  </Typography>
                }

              </ImageBox>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      </Snackbar>
    </StyledCard>
  );
};

export default ClinicDashboard;

