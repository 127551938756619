import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Button,
  Collapse,
  IconButton,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Snackbar,
  Alert,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HistoryIcon from '@mui/icons-material/History';

const HeaderBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#f4811e',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: '4px 4px 0 0',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 1200,
  margin: '0 auto',
  boxShadow: theme.shadows[3],
}));

const DetailBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#f5f5f5',
  borderRadius: theme.shape.borderRadius,
  '& .label': {
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.5),
  },
  '& .value': {
    marginBottom: theme.spacing(2),
  },
}));

const Row = ({ token, onMoveToOther, isSmallScreen }) => {
  const [open, setOpen] = useState(false);
  const [historyOpen, setHistoryOpen] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [historyError, setHistoryError] = useState(null);
  const [patientHistory, setPatientHistory] = useState([
    {
      date: '2024-01-15',
      diagnosis: 'Common Cold',
      prescription: 'Antihistamines, Cough Syrup',
      notes: 'Patient reported fever and cough'
    },
    {
      date: '2023-11-20',
      diagnosis: 'Hypertension Follow-up',
      prescription: 'Lisinopril 10mg',
      notes: 'Blood pressure under control'
    }
  ]);

  const fetchPatientHistory = async () => {
    setHistoryLoading(true);
    try {
      // Replace with actual API call
      const response = await fetch(`YOUR_API_ENDPOINT/patient-history/${token.patientDetails?.id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch patient history');
      }
      const data = await response.json();
      setPatientHistory(data);
      setHistoryError(null);
    } catch (err) {
      setHistoryError('Failed to load patient history');
      console.error('Error fetching patient history:', err);
    } finally {
      setHistoryLoading(false);
    }
  };

  const handleHistoryOpen = () => {
    setHistoryOpen(true);
    fetchPatientHistory();
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{token.number}</TableCell>
        {!isSmallScreen && <TableCell>{token.status}</TableCell>}
        <TableCell>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => onMoveToOther(token.number)}
            fullWidth={isSmallScreen}
          >
            Send To
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={isSmallScreen ? 3 : 4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <DetailBox sx={{ margin: 1 }}>
              <Box display="flex" alignItems="center" gap={2} mb={2}>
                <Typography variant="h6">
                  Patient Details
                </Typography>
                <Button
                  startIcon={<HistoryIcon />}
                  onClick={handleHistoryOpen}
                  variant="text"
                  color="primary"
                >
                  History
                </Button>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <div className="label">First Name</div>
                  <div className="value">{token.patientDetails?.firstName || '-'}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="label">Last Name</div>
                  <div className="value">{token.patientDetails?.lastName || '-'}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="label">Phone Number</div>
                  <div className="value">{token.patientDetails?.phoneNumber || '-'}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="label">Date of Birth</div>
                  <div className="value">{token.patientDetails?.dob || '-'}</div>
                </Grid>
                <Grid item xs={12}>
                  <div className="label">Address</div>
                  <div className="value">{token.patientDetails?.address || '-'}</div>
                </Grid>
                <Grid item xs={12}>
                  <div className="label">Existing Medical Condition</div>
                  <div className="value">{token.patientDetails?.medicalCondition || '-'}</div>
                </Grid>
              </Grid>
            </DetailBox>

            {/* History Dialog */}
            <Dialog
              open={historyOpen}
              onClose={() => setHistoryOpen(false)}
              fullWidth
              maxWidth="md"
            >
              <DialogTitle>
                Patient History - {token.patientDetails?.firstName} {token.patientDetails?.lastName}
              </DialogTitle>
              <DialogContent>
                {historyLoading ? (
                  <Box display="flex" justifyContent="center" p={3}>
                    <Typography>Loading patient history...</Typography>
                  </Box>
                ) : historyError ? (
                  <Alert severity="error" sx={{ mt: 2 }}>{historyError}</Alert>
                ) : (
                  <TableContainer component={Paper} sx={{ mt: 2 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Diagnosis</TableCell>
                          <TableCell>Prescription</TableCell>
                          <TableCell>Notes</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {patientHistory.map((visit, index) => (
                          <TableRow key={index}>
                            <TableCell>{visit.date}</TableCell>
                            <TableCell>{visit.diagnosis}</TableCell>
                            <TableCell>{visit.prescription}</TableCell>
                            <TableCell>{visit.notes}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setHistoryOpen(false)}>Close</Button>
              </DialogActions>
            </Dialog>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const DoctorDashboard = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [tokens, setTokens] = useState([
    {
      number: 'Q1',
      status: 'Now Serving',
      patientDetails: {
        firstName: 'John',
        lastName: 'Doe',
        phoneNumber: '+1 234-567-8900',
        dob: '1990-01-01',
        address: '123 Main Street, New York, NY 10001',
        medicalCondition: 'Hypertension, Type 2 Diabetes'
      }
    },
    {
      number: 'Q2',
      status: 'Waiting',
      patientDetails: {
        firstName: 'Jane',
        lastName: 'Smith',
        phoneNumber: '+1 234-567-8901',
        dob: '1985-05-15',
        address: '456 Oak Avenue, Los Angeles, CA 90001',
        medicalCondition: 'Asthma, Allergies'
      }
    },
    {
      number: 'Q3',
      status: 'Waiting',
      patientDetails: {
        firstName: 'Robert',
        lastName: 'Johnson',
        phoneNumber: '+1 234-567-8902',
        dob: '1978-12-20',
        address: '789 Pine Road, Chicago, IL 60601',
        medicalCondition: 'Lower back pain, Arthritis'
      }
    }
  ]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDepartmentDialog, setShowDepartmentDialog] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);

  const fetchTokens = async () => {
    try {
      const response = await fetch('YOUR_API_ENDPOINT/tokens');
      if (!response.ok) {
        throw new Error('Failed to fetch token data');
      }
      const data = await response.json();
      setTokens(data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch latest token data');
      console.error('Error fetching tokens:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTokens(); // Initial fetch

    // Set up polling every 15 seconds
    const intervalId = setInterval(fetchTokens, 15000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handleMoveToPharmacy = async (tokenNumber) => {
    try {
      const response = await fetch('YOUR_API_ENDPOINT/move-to-pharmacy', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tokenNumber }),
      });

      if (!response.ok) {
        throw new Error('Failed to move token to pharmacy');
      }

      // Refresh token data after successful move
      fetchTokens();
    } catch (err) {
      setError('Failed to move token to pharmacy');
      console.error('Error moving to pharmacy:', err);
    }
  };

  const handleMoveToOther = (tokenNumber) => {
    setSelectedToken(tokenNumber);
    setShowDepartmentDialog(true);
  };

  const handleDepartmentSelect = async (department) => {
    try {
      const response = await fetch('YOUR_API_ENDPOINT/move-to-department', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          tokenNumber: selectedToken,
          department
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to move token to department');
      }

      setShowDepartmentDialog(false);
      fetchTokens();
    } catch (err) {
      setError('Failed to move token to department');
      console.error('Error moving to department:', err);
    }
  };

  return (
    <StyledCard>
      <HeaderBox>
        <Typography variant="h5" component="h1" align="center" style={{ color: 'white' }}>
          Doctor Dashboard
        </Typography>
      </HeaderBox>
      <CardContent>
        {loading && tokens.length === 0 ? (
          <Box display="flex" justifyContent="center" p={3}>
            <Typography>Loading tokens...</Typography>
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="50px" />
                  <TableCell>Token No.</TableCell>
                  {!isSmallScreen && <TableCell>Status</TableCell>}
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tokens.map((token) => (
                  <Row
                    key={token.number}
                    token={token}
                    onMoveToOther={handleMoveToOther}
                    isSmallScreen={isSmallScreen}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Dialog
          open={showDepartmentDialog}
          onClose={() => setShowDepartmentDialog(false)}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle>Send Patient To</DialogTitle>
          <DialogContent>
            <Stack spacing={2} sx={{ mt: 2 }}>
              <Button variant="contained" onClick={() => handleDepartmentSelect('pharmacy')}>
                Pharmacy
              </Button>
              <Button variant="contained" onClick={() => handleDepartmentSelect('xray')}>
                X-Ray
              </Button>
              <Button variant="contained" onClick={() => handleDepartmentSelect('lab')}>
                Lab
              </Button>
              <Button variant="contained" onClick={() => handleDepartmentSelect('specialist')}>
                Specialist
              </Button>
              <Button variant="contained" onClick={() => handleDepartmentSelect('other')}>
                Other
              </Button>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDepartmentDialog(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={() => setError(null)}
        >
          <Alert severity="error" onClose={() => setError(null)}>
            {error}
          </Alert>
        </Snackbar>
      </CardContent>
    </StyledCard>
  );
};

export default DoctorDashboard;