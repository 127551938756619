import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  Card,
  CardContent,
  CardHeader,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Alert,
  Grid,
  Container
} from '@mui/material';

const ClinicRegistrationForm = () => {
  const { clinicId } = useParams();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [clinicInfo, setClinicInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    clinicId: clinicId,
    title: '',
    gender: '',
    phoneNo: '',
    email: '',
    dob: '',
    address: '',
    symptoms: '',
    emergencyContactName: '',
    emergencyContactNo: '',
    specialization: '',
    doctorToVisit: '',
    fname: '',
    lname: ''
  });

  useEffect(() => {
    fetchClinicInfo();
  }, [clinicId]);

  const fetchClinicInfo = async () => {
    try {
      const response = await fetch(
        `https://notif-service-662226239165.asia-southeast1.run.app/clinic/register-info?clinicId=${clinicId}`
      );
      
      if (!response.ok) {
        throw new Error('Failed to fetch clinic info');
      }
      
      const data = await response.json();
      setClinicInfo(data);
      setFormData(prev => ({
        ...prev,
        clinicId: data.clinicId
      }));
    } catch (err) {
      setError('Failed to load clinic information. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    if (name === 'specialization') {
      setFormData(prevState => ({
        ...prevState,
        doctorToVisit: '',
        specialization: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      const response = await fetch('https://notif-service-662226239165.asia-southeast1.run.app/patient/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Registration failed');
      }

      const data = await response.json();
      alert('Registration successful!');
      navigate('/registration-success', { state: { registrationData: data } });
    } catch (error) {
      setError(error.message || 'Registration failed. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md">
      <Card sx={{ mt: 4, mb: 4 }}>
        <CardHeader
          title="Patient Registration"
          subheader={
            clinicInfo && (
              <>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  {clinicInfo.clinicName}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {clinicInfo.address}
                </Typography>
              </>
            )
          }
        />

        {error && (
          <Box sx={{ px: 2, pb: 2 }}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}

        <CardContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Title</InputLabel>
                  <Select
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    
                    label="Title"
                  >
                    <MenuItem value="Mr">Mr</MenuItem>
                    <MenuItem value="Mrs">Mrs</MenuItem>
                    <MenuItem value="Ms">Ms</MenuItem>
                    <MenuItem value="Dr">Dr</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Gender</InputLabel>
                  <Select
                    name="gender"
                    value={formData.gender}
                    onChange={handleInputChange}
                    
                    label="Gender"
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="fname"
                  label="First Name"
                  value={formData.fname}
                  onChange={handleInputChange}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="lname"
                  label="Last Name"
                  value={formData.lname}
                  onChange={handleInputChange}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="email"
                  label="Email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="phoneNo"
                  label="Phone Number"
                  value={formData.phoneNo}
                  onChange={handleInputChange}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="dob"
                  label="Date of Birth"
                  type="date"
                  value={formData.dob}
                  onChange={handleInputChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="address"
                  label="Address"
                  multiline
                  rows={2}
                  value={formData.address}
                  onChange={handleInputChange}
                  
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="symptoms"
                  label="Symptoms"
                  multiline
                  rows={3}
                  value={formData.symptoms}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="emergencyContactName"
                  label="Emergency Contact Name"
                  value={formData.emergencyContactName}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="emergencyContactNo"
                  label="Emergency Contact Number"
                  value={formData.emergencyContactNo}
                  onChange={handleInputChange}
                />
              </Grid>

              {clinicInfo && (
                <>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Specialization</InputLabel>
                      <Select
                        name="specialization"
                        value={formData.specialization}
                        onChange={handleInputChange}
                      
                        label="Specialization"
                      >
                        {clinicInfo.specializations?.map((spec) => (
                          <MenuItem key={spec} value={spec}>
                            {spec}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Doctor</InputLabel>
                      <Select
                        name="doctorToVisit"
                        value={formData.doctorToVisit}
                        onChange={handleInputChange}
                    
                        disabled={!formData.specialization}
                        label="Doctor"
                      >
                        {formData.specialization &&
                          clinicInfo.doctors
                            ?.filter((doc) => doc.specialization === formData.specialization)
                            .map((doc) => (
                              <MenuItem key={doc.id} value={doc.id}>
                                {doc.name}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                    startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
                  >
                    {isSubmitting ? 'Registering...' : 'Register'}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => navigate(-1)}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default ClinicRegistrationForm;