import React, { useEffect, useState } from 'react';
import { auth, database } from '../../firebase'; // Update the import path as needed
import { ref, get, update } from 'firebase/database';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import {
  CircularProgress,
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  Button,
  AppBar,
  Toolbar,
  Switch,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import ClinicRegistrationForm from '../basicDetails/EditProfileForm';

const UserProfiles = () => {
  const [devices, setDevices] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      // await signOut(auth);
      // navigate('/login'); // Redirect to login page after logout
    } catch (error) {
      console.error("Error signing out: ", error);
      setError("Failed to log out. Please try again.");
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserDevicesAndProfiles(user.uid);
      } else {
        setDevices({});
        setLoading(false);
        // navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const fetchUserDevicesAndProfiles = async (userId) => {
    setLoading(true);
    try {
      const userDevicesRef = ref(database, `users/${userId}/devices`);
      const userDevicesSnapshot = await get(userDevicesRef);

      if (userDevicesSnapshot.exists()) {
        const deviceIds = Object.keys(userDevicesSnapshot.val());
        const deviceData = {};

        for (const deviceId of deviceIds) {
          const deviceRef = ref(database, `devices/${deviceId}`);
          const deviceSnapshot = await get(deviceRef);

          if (deviceSnapshot.exists()) {
            const device = deviceSnapshot.val();
            deviceData[deviceId] = {
              defaultProfileId: device.defaultProfileId,
              profiles: device.profiles || {}
            };
          }
        }

        setDevices(deviceData);
      } else {
        setDevices({});
      }
    } catch (err) {
      console.error("Error fetching user devices and profiles:", err);
      setError("Failed to load profiles. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleToggleDefault = async (deviceId, profileId) => {
    try {
      await update(ref(database, `devices/${deviceId}`), {
        defaultProfileId: profileId
      });

      setDevices(prevDevices => ({
        ...prevDevices,
        [deviceId]: {
          ...prevDevices[deviceId],
          defaultProfileId: profileId
        }
      }));
    } catch (error) {
      console.error("Error setting default profile:", error);
      alert('Failed to set default profile. Please try again.');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <>
      <AppBar position="static">
        <Toolbar  >
          <Box sx={{ display:"flex", gap:2, flexGrow: 1 }}>
            <Typography variant="h5" component="div" >
             Home
            </Typography>
            <Typography variant="h5" component="div" >
               Queue
            </Typography>
          </Box>
          {/* <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button> */}
        </Toolbar>
      </AppBar>
      <Box sx={{ width: "100vw", maxWidth: 800, margin: 'auto', mt: 0, p: 2 }}>
          <ClinicRegistrationForm />
      </Box>
    </>
  );
};

export default UserProfiles;